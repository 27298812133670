<template>
  <b-row>
    <b-col cols="12">
      <b-overlay
        :show="$apollo.loading || mutationLoading"
        spinner-variant="primary"
      >
        <b-card title="All Incubators">
          <!-- search input -->
          <div class="custom-search d-flex justify-content-between">
            <b-form-group>
              <div class="d-flex align-items-center">
                <label class="mr-1">Search</label>
                <b-form-input
                  v-model="searchTerm"
                  class="d-inline-block mr-1"
                  placeholder="Search Incubators"
                  type="text"
                />
              </div>
            </b-form-group>
            <b-form-group
              class="flex-fill"
              :state="!!selectedOrgId"
            >
              <div class="d-flex align-items-center">
                <label class="mr-1">Select Organization</label>
                <v-select
                  v-model="selectedOrgId"
                  class="flex-fill"
                  :close-on-select="true"
                  :get-option-label="option => option.title"
                  :options="partnerOrgs"
                  :reduce="option => option.id"
                  placeholder="Select Organization"
                />
              </div>
            </b-form-group>
          </div>

          <!-- table -->
          <vue-good-table
            :columns="columns"
            :pagination-options="{
              enabled: true,
              perPage:pageLength
            }"
            :rows="incubators"
            :search-options="{
              enabled: true,
              externalQuery: searchTerm }"
          >
            <template
              slot="table-row"
              slot-scope="props"
            >

              <span v-if="props.column.field === 'buttons'">
                <b-button
                  variant="outline-primary"
                  size="sm"
                  @click="selectedRow = props.row.originalIndex; $bvModal.show('view-incubator-modal')"
                >View Details</b-button>
                <b-button
                  v-if="selectedOrgId && props.row.usersAssociationtablesByAssociatedOrg && props.row.usersAssociationtablesByAssociatedOrg.length === 0"
                  variant="outline-success"
                  class="ml-50"
                  size="sm"
                  @click="selectedRow = props.row.originalIndex; $bvModal.show('join-incubator-modal')"
                >Associate</b-button>
                <b-button
                  v-else-if="selectedOrgId && props.row.usersAssociationtablesByAssociatedOrg && props.row.usersAssociationtablesByAssociatedOrg[0].role"
                  :to="{ name: 'partner-incubator-manager', params: { id: props.row.usersAssociationtablesByAssociatedOrg[0].id } }"
                  variant="outline-primary"
                  class="ml-50"
                  size="sm"
                >Incubator Manager</b-button>
              </span>

              <!-- Column: Common -->
              <span v-else>
                {{ props.formattedRow[props.column.field] }}
              </span>
            </template>
            <!-- pagination -->
            <template
              slot="pagination-bottom"
              slot-scope="props"
            >
              <div class="d-flex justify-content-between flex-wrap">
                <div class="d-flex align-items-center mb-0 mt-1">
                  <span class="text-nowrap">
                    Showing 1 to
                  </span>
                  <b-form-select
                    v-model="pageLength"
                    :options="['3','5','10']"
                    class="mx-1"
                    @input="(value)=>props.perPageChanged({currentPerPage:value})"
                  />
                  <span class="text-nowrap"> of {{ props.total }} entries </span>
                </div>
                <div>
                  <b-pagination
                    :per-page="pageLength"
                    :total-rows="props.total"
                    :value="1"
                    align="right"
                    class="mt-1 mb-0"
                    first-number
                    last-number
                    next-class="next-item"
                    prev-class="prev-item"
                    @input="(value)=>props.pageChanged({currentPage:value})"
                  >
                    <template #prev-text>
                      <feather-icon
                        icon="ChevronLeftIcon"
                        size="18"
                      />
                    </template>
                    <template #next-text>
                      <feather-icon
                        icon="ChevronRightIcon"
                        size="18"
                      />
                    </template>
                  </b-pagination>
                </div>
              </div>
            </template>
          </vue-good-table>
        </b-card>
      </b-overlay>
      <b-modal
        id="view-incubator-modal"
        title="View Details"
        hide-footer
      >
        <b-table
          v-if="incubators[selectedRow]"
          :fields="['title','official_email','official_phone','url','city','state','industry',{key: 'startup_stage', label: 'Stage'},'description']"
          stacked
          bordered
          :items="[incubators[selectedRow]]"
        >
          <template #cell(url)="data">
            <b-link
              :href="prependHttps(data.item.url)"
              target="_blank"
            >{{ data.item.url }}
            </b-link>
          </template>
        </b-table>
      </b-modal>
      <b-modal
        id="join-incubator-modal"
        class="font-medium-2"
        title="Join Incubator's Network"
        ok-title="Join"
        centered
        @ok="joinIncubator(incubators[selectedRow].id)"
      ><span
        v-if="selectedOrgId && incubators[selectedRow]"
      >Would you like to join the network of <strong class="text-primary d-inline-block">{{
        incubators[selectedRow].title
      }}</strong> with your organisation <strong class="text-primary d-inline-block">{{
        partnerOrgs.find(e => e.id === selectedOrgId).title
      }}</strong>?</span>
      </b-modal>
    </b-col>
  </b-row>
</template>

<script>
import {
  BButton, BCard, BFormGroup, BFormInput, BFormSelect, BLink, BPagination, BTable,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
// import store from '@/store'
import gql from 'graphql-tag'
import { getUserData } from '@/auth/utils'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BCard,
    BLink,
    BTable,
    VueGoodTable,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BButton,
    vSelect,
  },
  data() {
    return {
      pageLength: 5,
      dir: false,
      filter: null,
      columns: [
        {
          label: 'Incubator',
          field: 'title',
        },
        {
          label: 'Association',
          field(row) {
            if (!row.usersAssociationtablesByAssociatedOrg) return '-'
            const partner = { ...row.usersAssociationtablesByAssociatedOrg[0] }
            if (!partner.role) return 'Unassociated'
            if (partner.status && partner.status.toLowerCase() === 'accept') return 'Partner'
            return 'Network'
          },
          tdClass: 'text-capitalize',
          filterOptions: {
            enabled: true, // enable filter for this column
            customFilter: true, // use custom filter component
            placeholder: '-- No Filter --', // placeholder for filter input
            filterDropdownItems: [
              {
                value: 'Unassociated',
                text: 'Unassociated',
              }, {
                value: 'Partner',
                text: 'Partner',
              }, {
                value: 'Network',
                text: 'Network',
              }], // dropdown (with selected values) instead of text input
          },
          firstSortType: 'asc',
        },
        {
          label: '',
          field: 'buttons',
          sortable: false,
        },
      ],
      incubators: [],
      mutationLoading: false,
      partnerOrgs: [],
      searchTerm: '',
      selectedRow: null,
      selectedOrgId: null,
    }
  },
  methods: {
    prependHttps(url) {
      if (!url) return ''
      if (url.startsWith('http')) return url
      return `https://${url}`
    },
    // Insert into entry with incubator_id and organization_id into users_associationtables table with graphql mutate
    joinIncubator(incubatorId) {
      this.mutationLoading = true
      const mutation = gql`
        mutation {
          insert_users_associationtable_one(
            object: {
              organization_id: ${this.selectedOrgId},
              incubator_id: ${incubatorId},
              role: "partner",
            }
          ) {
            id
          }
        }
      `
      this.$apollo.mutate({
        mutation,
      })
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Successfully joined incubator\'s network.',
              icon: 'CompassIcon',
              variant: 'success',
            },
          })

          this.mutationLoading = false
          this.$apollo.queries.incubators.refetch()
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Something went wrong. Please try again later.',
              message: error.message,
              icon: 'CompassIcon',
              variant: 'danger',
            },
          })
          this.mutationLoading = false
        })
    },
  },
  apollo: {
    incubators: {
      query() {
        return gql`
          query Incubators {
            users_organizationtable(where: {type: {_ilike: "incubator"}}) {
              id
              title
              official_email
              official_phone
              url
              city
              state
              industry
              startup_stage
              description
              usersAssociationtablesByAssociatedOrg(where: {organization_id: {_eq: ${this.selectedOrgId} }}) @include(if: ${!!this.selectedOrgId}) {
                id
                role
                status
              }
            }
          }`
      },
      update: data => data.users_organizationtable,
    },
    partnerOrgs: {
      query() {
        return gql`
          query partner_organizations {
          users_organizationtable(where: {users_associationtables: { role: {_nilike: "mentor"},user_id: {_eq: "${getUserData().id}"}}})
           {
             id
             title
           }
        }`
      },
      update: data => data.users_organizationtable,
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
